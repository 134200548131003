import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import paymentService from "../../services/paymentService";

import "./PaymentPage.scss";

function PaymentPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();


  useEffect(() => {
  }, []);
  function confirm() {
    let txnRef = searchParams.get('txnRef');
    let transactionId = searchParams.get('transactionId');
    let transactionStatus = searchParams.get('transactionStatus');
    let payDate = searchParams.get('payDate');
    let hash = searchParams.get('hash');
    paymentService.verifyPayment({ txnRef: txnRef, transactionId: transactionId, transactionStatus: transactionStatus, payDate: payDate, hash: hash }).then(() => {
      navigate("/");
    })
  }
  return (
    <div className="wrapper-payment-page">

      <Button onClick={() => confirm()} variant="contained" className="my-btn btn-green large-size btn-contained">
        Xác nhận thanh toán
      </Button>
    </div>
  );
}

export default PaymentPage;

